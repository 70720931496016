
import ValidationMixin from "@/mixins/validation";
import {
  ProductTypes,
  SystemProduct,
} from "@/store/modules/product/product.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const productX = namespace("Product");

@Component({})
export default class EditProduct extends Mixins(ValidationMixin) {
  @productX.State(ProductTypes.DELETE_PRODUCT_STATE)
  public deleteProduct!: DefaultState;

  @productX.State(ProductTypes.PRODUCT_DATA)
  public userProduct!: SystemProduct;

  @productX.Mutation(ProductTypes.SET_DELETE_PRODUCT_DIALOG)
  public setDeleteProduct!: (deleteProduct: boolean) => void;

  @productX.Action(ProductTypes.DELETE_PRODUCT)
  public deleteSystemProduct!: () => Promise<void>;

  public updateProductStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeleteProductDialog(): void {
    this.setDeleteProduct(false);
  }
}
